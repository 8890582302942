<div class="container">
    <div class="row">
        <div class="modalbox success col-sm-8 col-md-6 col-lg-6 center animate">
            <div class="icon">
                <span class="fa fa-check"></span>
            </div>
            <h2 class="mt-4">Your order has been placed!</h2>
            <h5 class="mt-4">Your Order ID is {{orderId}}</h5>
            <h6 class="mt-4"> Thank You !!</h6>
            <button class="btn btn-transparent mt-2" [routerLink]="['']"> Continue to homepage </button>
        </div>
    </div>
</div>