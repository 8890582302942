import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

  @Input()
  products: any[] = [];

  @Output()
  next: EventEmitter<string> = new EventEmitter();

  constructor(private commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  onProceed() {
    this.next.emit();
  }

  calculateDuoCost(product: any) {
    product.price = (product.user * 100).toFixed(1);
  }

  calculateUmbrellaCost(product: any) {
    product.price = (product.user * 37).toFixed(1);
  }

  onUsersChange(product: any) {
    if (product.productName === 'Umbrella') this.calculateUmbrellaCost(product);
    else if (product.productName === 'Duo') this.calculateDuoCost(product);
  }

  onKeydown(e: any) {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8) || e.keyCode == 48)
      return false;
  }

  changeHandler(id) {
    for (var prod of this.products) {
      if (prod.productId == id && prod.recommended) {
        prod.recommended = false;
      } else if (prod.productId == id && !prod.recommended) {
        prod.recommended = true;
      }
    }
    this.commonService.storeProductsOnLocalStorage(this.products);
  }

  get canProceed() {
    return this.products?.some(p => p.recommended);
  }

  onCancel() {
    this.router.navigate(['']);
  }

  ngOnDestroy(): void {
  }

}
