<!-- Main Header-->
<header class="main-header">
    <div class="container">
        <div class="m-4 p-3"></div>
        <div class="main-header-subheading">Welcome to Cisco Secure</div>
        <div class="main-header-subheading">Your One Stop Solution Against Cyber Threats</div>
        <button class="btn button-pill-primary btn-lg pull-left ml-4 mt-5"><a target="blank"
                href="https://cisco.box.com/s/i7m8e3rcb8umr5r6rt0r5nislng6csqv">
                Start free trial</a></button>
        <div class="m-4 p-3"></div>
    </div>

</header>

<!-- Necessity -->
<section class="page-section" id="whyCyber">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading">Why Cybersecurity?</h2>
            <h3 class="section-subheading text-muted">Small businesses are at more risk than ever before</h3>
        </div>
        <div class="row text-center d-flex justify-content-center">
            <div class="col-md-2">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="security-img" src="../../../assets/img/72-percent.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h2 class="my-3">72% </h2>
					<p class="text-muted">say they have had a cyber attack in the past year.</p>
				</div>
            </div>
            <div class="col-md-2">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="doller-img" src="../../../assets/img/1.24M.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h2 class="my-3">1.24m </h2>
					<p class="text-muted">the average cost of a compromise in 2019 ($).</p>
				</div>
            </div>
            <div class="col-md-2">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="security-img" src="../../../assets/img/74 response time.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h2 class="my-3">74% </h2>
					<p class="text-muted">say their response time has increased or is the same.</p>
				</div>
            </div>
            <div class="col-md-2">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="security-img" src="../../../assets/img/69 percent targeted.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h2 class="my-3">69% </h2>
					<p class="text-muted">agree attacks are the more targeted and sophisticated.</p>
				</div>
            </div>
            <div class="col-md-2">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="security-img" src="../../../assets/img/58 password practices.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h2 class="my-3">58% </h2>
					<p class="text-muted">say they don't have visibility into passwords practices.</p>
				</div>
            </div>
        </div>
    </div>
</section>
<!--Security not meeting-->
<section class="page-section bg-blue">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading">Resulting in security that isn’t meeting the needs of small business </h2>
        </div>
        <div class="row text-center">
            <div class="col-md-4">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="one-img" src="../../../assets/img/security-system.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h4 class="my-3">Too complex </h4>
					<p class="text-muted-reverse">Disparate security systems that have been deployed with little to no integration are making even minor tasks overly complex.</p>
				</div>
            </div>
            <div class="col-md-4">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="two-img" src="../../../assets/img/network-speed.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h4 class="my-3">Too slow </h4>
					<p class="text-muted-reverse">Overly complex infrastructure is negatively impacting speed and agility,
                    increasing response times and draining resource.</p>
				</div>
            </div>
            <div class="col-md-4">
				<div class="icon-left">
					<span class="fa-stack fa-4x">
						<img class="three-img" src="../../../assets/img/business-network-protection.png" alt="">
					</span>
				</div>
				<div class="content-right">
					<h4 class="my-3">Too rigid </h4>
					<p class="text-muted-reverse">Most vendors require lengthy commitments, which does not suit a
                    constantly changing business.</p>
				</div>
            </div>
        </div>
    </div>
</section>
<!-- Solution-->
<section class="page-section" id="solution">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading">Cisco Simplified Approach to Security</h2>
            <p class="text-muted-reverse">As the threat landscape becomes more difficult to deal with,
                security is rising up the list of priorities for many small businesses. But it's not always clear where
                to start or which approach to take, especially in fast-paced businesses that are changing all the time.
                That's why our security is developed with your needs in mind.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="feature-thumb">
					<div class="icon-left">
						<div class="timeline-image">
							<img class="three-img" src="../../../assets/img/planning-for-sase.jpg" alt="">
						</div>
					</div>
					<div class="content-right">
						<h3>Simplicity</h3>
						<p>on what matters with simplified security that works for everyone.</p>
						<p>
							<label class="fa fa-check green"></label> Quick and painless deployment
						</p>
						<p><label class="fa fa-check green"></label> Simplified threat detection </p>
					</div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-thumb">
					<div class="icon-left">
						<div class="timeline-image">
							<img class="three-img" src="../../../assets/img/network-agility.jpg" alt="">
						</div>
					</div>
					<div class="content-right">
						<h3>Agility</h3>
						<p>is vital for many small businesses, but it shouldn't stop with security. Identify and
							respond to threats before they impact the business.
						</p>
						<p><label class="fa fa-check green"></label> Fast remediation </p>
						<p><label class="fa fa-check green"></label> Prioritise what's important</p>
					</div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-thumb">
					<div class="icon-left">
						<div class="timeline-image mr-2">
							<img class="three-img" src="../../../assets/img/security-scalability.jpg" alt="">
						</div>
					</div>
					<div class="content-right">
						<h3>Scalability</h3>
						<p>the business with security that scales alongside you.</p>
						<p><label class="fa fa-check green"></label> Improve efficiency </p>
						<p><label class="fa fa-check green"></label> Integrate at your own pace </p>
					</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Offering-->
<section class="page-section bg-light" id="offering">
    <div class="container">
        <div class="text-center">
            <h2 class="section-heading">Cisco Offering</h2>
            <h3 class="section-subheading text-muted-reverse">Protect your users both on and off the network by blocking
                malicious Internet destinations before a connection is ever established.
            </h3>
        </div>
        <ul class="timeline">
            <li>
                <a class="timeline-image">
                    <h2>1</h2>
                </a>
                <div class="timeline-panel">
                    <div class="timeline-heading mt-4">
                        <h4>Meraki MX</h4>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted-reverse">Meraki MX can be remotely deployed in minutes and gives you
                            complete
                            control over the users, content, and applications on your netwrok.</p>
                    </div>
                    <a class="mt-3 bounce" data-bs-toggle="modal" href="#offeringModal1">
                        <span class="fa fa-angle-double-right"></span> Read More
                    </a>
                </div>
                <div class="timeline-panel mt-4 first-row-timepanel" style="float: left;">
                    <img src="../../../assets/img/images/Cisco Secure_Firewall-Light.png" alt="">
                </div>
            </li>
            <li class="timeline-inverted">
                <div class="timeline-image">
                    <h2>2</h2>
                </div>
                <div class="timeline-panel">
                    <div class="timeline-heading mt-4">
                        <h4>Cisco Secure Umbrella</h4>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted-reverse">By enforcing security at the DNS and IP layers, Umbrella blocks
                            requests
                            to malware, ransomeware, phishing, and botnets before a connection is even
                            established.<br /> <br /> Monitoring DNS requests can be an easy way to reduce blind spots
                            and provide better accuracy and detection of compromised systems, improving network
                            protection.
                        </p>
                    </div>
                    <a class="mt-3 bounce" data-bs-toggle="modal" href="#offeringModal3">
                        <span class="fa fa-angle-double-right"></span> Read More
                    </a>
                </div>
                <div class="timeline-panel mt-4  second-row-timepanel" style="float: right;">
                    <img class="img-umbrella" src="../../../assets/img/images/Cisco Secure_Umbrella - Light.png" alt="">
                </div>
            </li>
            <li>
                <div class="timeline-image">
                    <h2>3</h2>
                </div>
                <div class="timeline-panel">
                    <div class="timeline-heading mt-4">
                        <h4>Cisco Secure Access by Duo</h4>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted-reverse">Cisco Duo verifies the identity of all users with the strong two
                            factor
                            authentication before checking the devices meet security standards - safeguarding against
                            vulnerable or compromised endpoints.
                        </p>
                    </div>
                    <a class="mt-3 bounce" data-bs-toggle="modal" href="#offeringModal4">
                        <span class="fa fa-angle-double-right"></span> Read More
                    </a>
                </div>
                <div class="timeline-panel mt-4 third-row-timepanel" style="float: left;">
                    <img src="../../../assets/img/images/Cisco Secure_Access by Duo-Light.png" alt="">
                </div>
            </li>
            <li class="timeline-inverted">
                <div class="timeline-image">
                    <h6 style="margin-top: 10px; font-size: 14px;">
                        Securing
                        <br />
                        your
                        <br />
                        network
                    </h6>
                </div>
            </li>
        </ul>
    </div>
</section>
<!-- Guidelines-->
<section class="page-section bottom-row">
    <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-4 text-center">
                <img style="width: -webkit-fill-available;" src="assets/img/Multiple People on Grid - Zero Trust.png">
            </div>
            <div class="col-lg-8 text-center">
                <h2 class="text-dark mt-2 mb-4">Prevalent Cybersecurity Issues of SMBs</h2>
                <p>The smallest cybersecurity incident can have
                    devastating impacts. Learn how to protect your
                    business from the most common threats with
                    resources from the Australian Cyber Security
                    Centre (ACSC). </p>

                <button class="btn button-pill-primary btn-md"><a target="blank"
                        href="https://www.cyber.gov.au/sites/default/files/2019-10/Small%20Business%20Cyber%20Security%20Guide.pdf">
                        SMB Cyber Guide</a></button> &nbsp;
                <button class="btn button-pill-secondary btn-md"> <a target="blank"
                        href="https://www.cyber.gov.au/sites/default/files/2021-05/Cyber%20Security%20and%20Australian%20Small%20Businesses%20Survey%20Results%20-%2020201130.pdf">SMB
                        Cyber Survey</a></button>
            </div>
        </div>
    </div>
</section>

<hr style="margin: 0 3rem;" />

<!-- Footer-->
<footer class="footer py-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 text-lg-start">Copyright &copy; Cisco 2021</div>
            <div class="col-lg-4 my-3 my-lg-0">
                <a class="mx-2" href="#!">
                    <svg width="32" height="32" viewBox="0 0 32 32" role="img" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path
                            d="m31.38462,7.26845c-1.15385,0.48174 -2.30769,0.84304 -3.58974,0.96348c1.28205,-0.72261 2.30769,-3.54002 2.82051,-4.21523c-1.28205,1.27739 -2.5641,0.79565 -3.97436,0.55478c-1.28205,-0.79565 -2.94872,-0.07304 -4.74359,-0.07304c-3.46154,0 -6.28205,2.64957 -6.28205,5.90132c0,0.48174 0,0.96348 0.12821,1.32479c-5.25642,-0.24087 -9.8718,-2.52914 -12.94872,-6.31003c-0.64103,-0.84304 -0.89744,2.0948 -0.89744,3.17871c0,2.0474 1.15385,3.85393 2.82051,4.93784c-1.02564,-0.12043 -2.05128,-0.3613 -2.82051,-0.72261c0,0 0,0 0,0.12044c0,2.89044 2.17949,5.29914 5,5.78088c-0.51282,0.12044 -1.02564,0.24087 -1.66667,0.24087c-0.38461,0 -0.76923,0 -1.15384,-0.12043c0.76923,2.4087 3.07692,4.09479 5.89743,4.09479c-2.17948,1.56566 -4.87179,2.52914 -7.82051,2.52914c-0.51282,0 -1.02564,0 -1.53846,-0.12043c2.82051,1.50152 6.15385,2.64957 9.61538,2.64957c11.66667,0 17.94872,-9.03264 17.94872,-16.86092l0,-0.72261c1.28205,-0.84304 2.30769,-1.92696 3.20513,-3.13131"
                            fill="#fff"></path>
                    </svg>
                </a>
                <a class="mx-2" href="#!">
                    <svg width="32" height="32" viewBox="0 0 32 32" role="img" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path
                            d="m26.62006,4l-22.2403,0c-0.7622,0 -1.37976,0.59894 -1.37976,1.33804l0,21.56635c0,0.73891 0.61756,1.33803 1.37976,1.33803l11.97343,0l0,-9.38796l-3.25793,0l0,-3.65868l3.25793,0l0,-2.69815c0,-3.13113 1.97213,-4.83613 4.85266,-4.83613c1.37975,0 2.56571,0.09955 2.91135,0.14415l0,3.2722l-1.99788,0.00091c-1.56654,0 -1.86993,0.72183 -1.86993,1.7812l0,2.33582l3.7362,0l-0.48652,3.65868l-3.24968,0l0,9.38796l6.37067,0c0.76191,0 1.37975,-0.59912 1.37975,-1.33803l0,-21.56635c0,-0.7391 -0.61784,-1.33804 -1.37975,-1.33804"
                            fill="#fff"></path>
                    </svg>
                </a>
                <a class="mx-2" href="#!">
                    <svg width="32" height="32" viewBox="0 0 32 32" role="img" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path
                            d="m24.80382,24.53603l-3.70694,0l0,-5.62559c0,-1.34209 -0.02431,-3.06801 -1.92709,-3.06801c-1.92986,0 -2.22361,1.46262 -2.22361,2.97171l0,5.72189l-3.70347,0l0,-11.56902l3.55417,0l0,1.58181l0.05069,0c0.49445,-0.90976 1.70486,-1.86868 3.50903,-1.86868c3.75347,0 4.44722,2.39528 4.44722,5.51111l0,6.34478zm-15.74236,-13.1495c-1.19097,0 -2.15139,-0.934 -2.15139,-2.08552c0,-1.15084 0.96042,-2.08485 2.15139,-2.08485c1.18611,0 2.14931,0.93401 2.14931,2.08485c0,1.15152 -0.9632,2.08552 -2.14931,2.08552l0,0zm1.85486,13.1495l0,-11.56902l-3.71111,0l0,11.56902l3.71111,0zm15.73403,-20.65724l-21.30556,0c-1.01736,0 -1.84444,0.78249 -1.84444,1.74815l0,20.74545c0,0.96499 0.82708,1.74882 1.84444,1.74882l21.30556,0c1.02014,0 1.84931,-0.78383 1.84931,-1.74882l0,-20.74545c0,-0.96566 -0.82917,-1.74815 -1.84931,-1.74815l0,0z"
                            fill="#fff" fill-rule="evenodd"></path>
                    </svg>
                </a>
            </div>
            <div class="col-lg-4 text-lg-end">
                <a class="link-light text-decoration-none me-3" href="#!">Privacy Policy</a>
                <a class="link-light text-decoration-none" href="#!">Terms of Use</a>
            </div>
        </div>
    </div>
</footer>


<!-- Offering Modals-->

<!-- Offering Meraki MX modal popup-->
<div class="portfolio-modal modal fade" id="offeringModal1" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="close-modal" data-bs-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" />
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="modal-body">
                            <h2 class="text-uppercase">Meraki MX</h2>
                            <img src="assets/img/miraki-mx.jpg" alt="">
                            <p class="item-intro text-muted">The Cisco Meraki MX are
                                multifunctional security and SD-WAN enterprise appliances with a wide set of
                                capabilities to address multiple
                                use cases–from an all-in-one device. Organizations of all sizes and across all
                                industries rely on the MX to deliver secure connectivity to
                                hub locations or multi-cloud environments, as well as application quality of experience
                                (QoE), through advanced analytics with machine
                                learning.
                                The MX is 100% cloud-managed, so installation and remote management is truly zero touch,
                                making it ideal for distributed branches,
                                campuses, and data center locations. Natively integrated with a comprehensive suite of
                                secure network and assurance capabilities, the MX
                                eliminates the need for multiple appliances. These capabilities include
                                application-based firewalling, content filtering, web search filtering,
                                SNORT®-based intrusion detection and prevention, Cisco Advanced Malware Protection
                                (AMP), site-to-site Auto VPN, client VPN, WAN
                                and cellular failover, dynamic path selection, web application health, VoIP health, and
                                more. SD-WAN can be easily be extended to deliver
                                optimized access to resources in public and private cloud environments with virtual MX
                                appliances (vMX). Public clouds supported with
                                vMX include Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform, and
                                Alibaba Cloud and private cloud support through
                                Cisco NFVIS.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Offering Cisco Umbrella modal popup-->
<div class="portfolio-modal modal fade" id="offeringModal3" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="close-modal" data-bs-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" />
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="modal-body">
                            <h2 class="text-uppercase">Cisco Umbrella</h2>
                            <img src="assets/img/umbrella.png" alt="">
                            <p class="item-intro text-muted">Cisco Umbrella offers flexible, cloud-delivered security
                                when and how you need it. It combines multiple security functions into one solution, so
                                you can extend protection to devices, remote users, and distributed locations anywhere.
                                Umbrella is the easiest way to effectively protect your users everywhere in minutes.

                                As a leading provider of recursive DNS services, we’ve helped businesses of all sizes
                                and
                                industries connect to the internet with confidence. We’ve built a reputation on easy
                                deployment and powerful protection anywhere users work.

                                To help organizations embrace direct internet access, in addition to DNS-layer security
                                and interactive threat intelligence, Cisco Umbrella now includes secure web gateway,
                                firewall, and cloud access security broker (CASB) functionality, plus integration with
                                Cisco SD-WAN, delivered from a single cloud security service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Offering Cisco Duo modal popup-->
<div class="portfolio-modal modal fade" id="offeringModal4" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="close-modal" data-bs-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" />
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="modal-body">
                            <h2 class="text-uppercase">Cisco Duo</h2>
                            <img src="assets/img/duo.png" alt="">
                            <p class="item-intro text-muted">Cisco Duo allows secure connections to applications (on
                                premises or in the cloud). Using
                                multi-factor authentication (MFA) and contextual user access policies, organizations can
                                verify an employee’s identity to ensure they are who they say they are and add more
                                checks on the trustworthiness of devices through security health inspections.

                                With MFA a person’s username and password are not enough to assume an individual’s
                                identity, and the risk of a hacker getting access to critical data is significantly
                                reduced.

                                Note: Multi-factor authentication is an authentication method in which a computer user
                                is granted access only after successfully presenting two or more pieces of evidence (or
                                factors) to an authentication mechanism: knowledge (something the user and only the user
                                knows), possession (something the user and only the user has), and inherence (something
                                the user and only the user is).</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>