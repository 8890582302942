<section class="pricing-table">
    <div class="container">
        <!-- <div class="d-flex">
            <ul class="list-inline mx-auto justify-content-center">
                <li class="list-inline-item"><span class="dot-md"></span></li>
                <li class="list-inline-item"><span class="active-dot"></span></li>
                <li class="list-inline-item"><span class="dot-md"></span></li>
                <li class="list-inline-item"><span class="dot-md"></span></li>
            </ul>
        </div> -->
        <div class="text-center block-heading">
            <h6 class="mb-4">
                Based on your answers, we recommend:
            </h6>
            <div class="d-flex">
                <ul class="list-inline mx-auto justify-content-center">
                    <li class="list-inline-item" *ngFor="let product of recommendedProducts">{{product}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-md-1 col-lg-1"></div>
            <div class="col-md-4 col-lg-3 mt-2" *ngFor="let p of products; let index = index">
                <div class="item">
                    <div class="col-md d-flex justify-content-end">
                        <div class="round d-flex justify-content-center">
                            <input type="checkbox" id="check+{{index}}" [checked]="p.recommended"
                                (change)='changeHandler(p.productId)' />
                            <label for="check+{{index}}" id="index"></label>
                        </div>
                    </div>
                    <div class="heading">
                        <h3>{{p.productName}}</h3>
                    </div>
                    <div class="price">
                        <h4>${{p.price}}</h4>
                    </div>
                    <div class="features">
                        <h4 *ngIf='index == 0'><span class="feature"> upfront </span>
                        </h4>
                        <h4 *ngIf='index != 0'><span class="feature"> per year </span>
                        </h4>
                    </div>
                    <div class="d-flex justify-content-center mt-4 mb-4">
                        <img height="60" width="auto" [src]="p.logo">
                    </div>
                    <div class="mt-4 text-center" *ngIf='index == 0'>
                        <p style="line-height: 1.72;">Meraki MX are multifunctional security and SD-WAN enterprise
                            appliances.</p>
                    </div>
                    <div class="mt-4 mb-4 text-center" *ngIf='index != 0'>
                        <span>Est. cost for <input type="number" min="1" (keydown)='onKeydown($event)'
                                (input)='onUsersChange(p)' [(ngModel)]='p.user'>
                            users</span>
                        <div class='slider mt-4'>
                            <input type="range" min="1" max="100" [(ngModel)]="p.user"
                                (ngModelChange)="onUsersChange(p)" value="20">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-lg-1"></div>
        </div>
        <div class="row mb-4" style="margin-top: 2rem;">
            <div class="col-md-12 text-center">
                <button class="btn btn-transparent btn-md btn-mr" (click)='onPrevious()'>Back</button>
                <button class="btn btn-primary btn-md" [disabled]='!canProceed' (click)='onNext()'>Proceed</button>
            </div>
        </div>
    </div>
</section>