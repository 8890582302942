<div class="content">
    <div class="container">
        <!-- <div class="d-flex">
            <ul class="list-inline mx-auto justify-content-center">
                <li class="list-inline-item"><span class="dot-md"></span></li>
                <li class="list-inline-item" *ngIf='isGetStarted'><span class="dot-md"></span></li>
                <li class="list-inline-item"><span class="dot-md"></span></li>
                <li class="list-inline-item"><span class="active-dot"></span></li>
            </ul>
        </div> -->
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-7 col-xs-12">
                <div class="box">
                    <h3 class="box-title">Shipping Details</h3>
                    <div class="row mb-2">
                        <div class="col-lg-3 col-md-12">
                            <h6>Full Name :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.firstName}} {{shippingDetails?.lastName}}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-3 col-md-12">
                            <h6>Country :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.country}}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-3 col-md-12">
                            <h6>Company Name :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.companyName}}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-3 col-md-12">
                            <h6>Job Title :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.jobTitle}}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-3 col-md-12">
                            <h6>Business Email :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.businessEmail}}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-3 col-md-12">
                            <h6>Business Phone :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.businessPhone}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-12">
                            <h6>Message :</h6>
                        </div>
                        <div class="col-lg-9 col-md-12">
                            <p>{{shippingDetails?.message}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
                <div class="widget">
                    <h4 class="widget-title">Order Summary</h4>
                    <div class="summary-block" *ngFor="let item of filterProducts(products);let index = index">
                        <div class="summary-content">
                            <div class="summary-head">
                                <h5 class="summary-title">{{item.productName}}</h5>
                            </div>
                            <div class="summary-price">
                                <p class="summary-text"> {{item.currency}} {{item.price}} </p>
                                <span class="summary-small-text pull-right"
                                    *ngIf="item.productName != 'Meraki MX'">{{item.user}} user</span>
                            </div>
                        </div>
                    </div>
                    <div class="summary-block">
                        <div class="summary-content">
                            <div class="summary-head">
                                <h5 class="summary-title">Total</h5>
                            </div>
                            <div class="summary-price">
                                <p class="summary-text"> $ {{totalPrice(filterProducts(products))}} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <button class="btn btn-transparent btn-md btn-mr" (click)='onPrevious()'>Back</button>
                <button class="btn btn-primary btn-md" (click)='onSubmit()'>Confirm Order</button>
            </div>
        </div>
    </div>
</div>