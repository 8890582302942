<nav class="navbar navbar-expand-lg navbar-light" id="mainNav">
    <div class="container-fluid" style="padding: 0 30px;">
        <div class="d-flex align-items-center">
            <a class="navbar-brand" href="#page-top"><img src="../assets/img/Cisco_Secure.jpg" alt="..." /></a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars ms-1"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto py-4 py-lg-0">
                <li class="nav-item"><a class="nav-link" [routerLink]="['']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">Why Cybersecurity?</a></li>
				<li class="nav-item"><a class="nav-link" [routerLink]="['/products']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">Products</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/get-started']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">Get Started</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/contact-us']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
            </ul>
        </div>
    </div>
</nav>
<div class="main-content-layout">
    <router-outlet></router-outlet>
</div>