import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  bundleUsers: number = 20;
  bundleCalculatedValue: any;
  activeTab = 'monthly';
  recommendedProducts = [];

  @Input()
  products: any[] = [];

  @Output()
  next: EventEmitter<string> = new EventEmitter();

  @Output()
  previous: EventEmitter<string> = new EventEmitter();

  constructor(
    private questionnaireService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }

  updateProduct() {
    for (var prod of this.products) {
      for (var recProd of this.recommendedProducts) {
        if (prod.productName == recProd) {
          prod.recommended = true;
        }
      }
    }
  }

  onNext() {
    this.next.emit();
  }

  onPrevious() {
    this.previous.emit();
  }

  changeHandler(id) {
    for (var prod of this.products) {
      if (prod.productId == id && prod.recommended) {
        prod.recommended = false;
      } else if (prod.productId == id && !prod.recommended) {
        prod.recommended = true;
      }
    }
  }

  get canProceed() {
    return this.products?.some(p => p.recommended);
  }

  calculateDuoCost(product: any) {
    product.price = (product.user * 100).toFixed(1);
  }

  calculateUmbrellaCost(product: any) {
    product.price = (product.user * 37).toFixed(1);
  }

  onUsersChange(product: any) {
    if (product.productName === 'Umbrella') this.calculateUmbrellaCost(product);
    else if (product.productName === 'Duo') this.calculateDuoCost(product);
  }

  onKeydown(e: any) {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8) || e.keyCode == 48)
      return false;
  }

  evaluateProducts() {
    this.recommendedProducts = [];
    
    for (var p of this.products) {
      if (p.recommended) {
        this.recommendedProducts.push(p.productName);
      }
      this.activeTab = this.products[0].plan;
    }
  }
}
